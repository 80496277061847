"use client"
import { fetchCategories } from '@/hooks/fetchCategories';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';

const Footer = () => {
    const [categories, setCategories] = useState([]); // State to store fetched categories
    const [loading, setLoading] = useState(false); // State to track loading status
    const [error, setError] = useState(null); // State to track errors

    useEffect(() => {
        // Fetch categories when the component mounts
        fetchCategories(setCategories, setLoading, setError);
    }, []); // Empty dependency array ensures this runs once on mount

    return (

        <div className='w-full bg-secondary relative'>


            {/* ss */}

            <div className="max-w-[1440px] mx-auto">
                <footer className="px-5 py-12">
                    <div className='grid md:grid-cols-4  grid-cols-2 '>

                        <div>
                            <h3 className="font-semibold text-lg mb-3 text-white pb-2">Useful Links</h3>
                            <ul>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="/about" className="inline-block text-footerText hover:underline  hover:text-primary/65">About Us</Link>
                                </li>

                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="/login" className="inline-block text-footerText hover:underline  hover:text-primary/65">Login</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="/register" className="inline-block text-footerText hover:underline  hover:text-primary/65">Register</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="/login" className="inline-block text-footerText hover:underline  hover:text-primary/65">Forgot Password</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="">
                            <h2 className="font-semibold text-lg mb-3 text-white pb-2">Help & Support</h2>
                            <ul>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="https://terms.iworkfreelancer.com" target="_blank" className="inline-block text-footerText hover:underline  hover:text-primary/65">Terms & Conditions</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="https://policy.iworkfreelancer.com" target="_blank" className="inline-block text-footerText hover:underline  hover:text-primary/65">Privacy & Policy</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="/jobs" className="inline-block text-footerText hover:underline  hover:text-primary/65">Job Packages</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="/jobs" className="inline-block text-footerText hover:underline  hover:text-primary/65">Jobs Featured</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="/client/postproject" className="inline-block text-footerText hover:underline  hover:text-primary/65">Post A Job</Link>
                                </li>
                            </ul>
                        </div>



                        <div className="">
                            <h2 className="font-semibold text-lg mb-3 text-white pb-2">Other Links</h2>
                            <ul>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="/client/forum" className="inline-block text-footerText hover:underline  hover:text-primary/65">Communit Forum</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="/client/dispute" className="inline-block text-footerText hover:underline  hover:text-primary/65">Dispute Resolution</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="#" className="inline-block text-footerText hover:underline  hover:text-primary/65">School Management</Link>
                                </li>
                                <li className='mb-2 hover:translate-x-2 transition-transform duration-300 '>
                                    <Link href="#" className="inline-block text-footerText hover:underline  hover:text-primary/65">Meclon Consultancy</Link>
                                </li>

                            </ul>
                        </div>


                        <div className="">
                            <h2 className="font-semibold text-lg mb-3 text-white pb-2">Featured Categories</h2>
                            <ul>

                                {loading ? (
                                    <li>Loading categories...</li>
                                ) : error ? (
                                    <li>{error}</li>
                                ) : (
                                    categories.slice(0, 5).map((category, index) => (
                                        <li key={index} className="mb-2 hover:translate-x-2 transition-transform duration-300">
                                            <Link href={{
                                                pathname: "jobs",
                                                query: { category: category.name },
                                            }}
                                                prefetch={false}
                                                scroll={true}
                                                className="block text-footerText hover:underline hover:text-primary">{category.name}</Link>
                                        </li>
                                    ))
                                )}
                            </ul>
                        </div>

                    </div>

                    <div className="flex flex-col md:justify-between md:flex-row justify-start items-center mt-2 pt-4 border-t border-gray-500 text-white space-y-2 md:space-y-0 md:space-x-4">
                        <p className="text-base">
                            &copy; Power by{" "}
                            <Link target='_blank' href="https://meclonconsultancy.co.ke" className="text-blue-400 hover:underline">
                                Meclon Consultancy Ltd
                            </Link>.
                        </p>

                        <p className="text-base">
                            &copy; {new Date().getFullYear()} iwork. All Rights Reserved.
                        </p>
                    </div>

                </footer>
            </div>
        </div>

    );
}

export default Footer;
