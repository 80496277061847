4// Reusable fetchCategories function
export const fetchCategories = async (setCategories, setLoading, setError) => {
    try {
        setLoading(true); // Set loading state to true before the fetch starts

        const response = await fetch(`${process.env.NEXT_PUBLIC_BACKEND_API}/v1/categories`);

        if (!response.ok) {
            throw new Error('Failed to fetch categories');
        }

        const data = await response.json();

        setCategories(data); // Set the categories data to state
        setLoading(false);    // Set loading state to false after the data is fetched
    } catch (err) {
        setLoading(false);  // Set loading state to false if there’s an error
        setError(err.message); // Set the error message if something goes wrong
        console.error('Error:', err.message); // Log the error
    }
};
